import Typography from "@material-ui/core/Typography"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import Carousel from "."
import { AllReference } from "./__generated__/AllReference"

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(4)}px;
  padding-top: ${({ theme }) => theme.spacing(6)}px;
`

const Img = styled.img`
  border: ${({ theme }) =>
    `${theme.spacing(0.5)}px solid ${theme.palette.primary.light}`};
  border-radius: 50%;
  box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%), 0 3px 4px 0 rgb(0 0 0 / 14%),
    0 1px 8px 0 rgb(0 0 0 / 12%);
`

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
const FlexColumn = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
  transform: translateY(40%);
  > * {
    line-height: 1.1 !important;
  }
`

const Body = styled(({ ...props }) => (
  <Typography variant="body2" {...props} />
))`
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  padding-left: ${({ theme }) => theme.spacing(5)}px;
  padding-right: ${({ theme }) => theme.spacing(5)}px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: 0;
    padding-top: ${({ theme }) => theme.spacing(5)}px;
  }
`

const Reviews: React.FC = () => {
  const {
    allWpReferentie: { nodes },
  } = useStaticQuery<AllReference>(graphql`
    query AllReference {
      allWpReferentie {
        nodes {
          id
          referentieFields {
            author
            description
            excerpt
            image {
              localFile {
                childImageSharp {
                  resize(
                    width: 125
                    height: 125
                    cropFocus: CENTER
                    quality: 90
                  ) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const reviews = nodes.map(({ referentieFields }) => (
    <Wrapper>
      <Flex>
        <Img
          src={String(
            referentieFields?.image?.localFile?.childImageSharp?.resize?.src
          )}
        />

        <FlexColumn>
          <Typography variant="body2">{referentieFields?.author}</Typography>
          <Typography variant="h6" component="p" color="primary">
            {referentieFields?.description}
          </Typography>
        </FlexColumn>
      </Flex>
      <Body>{referentieFields?.excerpt}</Body>
    </Wrapper>
  ))

  return <Carousel slides={reviews} />
}

export default Reviews
