import { Typography } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Paper from "@material-ui/core/Paper"
import { graphql, Link, PageProps, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import ContactButton from "../components/base/contactButton"
import Layout from "../components/base/layout"
import Center from "../components/base/layout/center"
import Spacer from "../components/base/layout/spacer"
import WpContent from "../components/base/layout/wpContent"
import Cards from "../components/cards"
import Reviews from "../components/carousel/reviews"
import ContactForm from "../components/contactForm"
import HeroImage from "../components/heroImage"
import Section from "../components/section"
import SEO from "../components/SEO"
import { HomePageQuery } from "./__generated__/HomePageQuery"

const TextWrapper = styled(Paper)`
  padding: ${({ theme }) =>
    `${theme.spacing(4)}px ${theme.spacing(8)}px ${theme.spacing(6)}px`};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding-left: ${({ theme }) => theme.spacing(6)}px;
    padding-right: ${({ theme }) => theme.spacing(6)}px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding-left: ${({ theme }) => theme.spacing(2)}px;
    padding-right: ${({ theme }) => theme.spacing(2)}px;
  }
`

const PaddingCenter = styled(Center)`
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding-top: ${({ theme }) => theme.spacing(4)}px;
  }
`

const IndexPage: React.FC<PageProps> = () => {
  const { wpPage } = useStaticQuery<HomePageQuery>(graphql`
    fragment SectionImage on ImageSharp {
      fluid(maxHeight: 500, maxWidth: 500, quality: 90) {
        ...GatsbyImageSharpFluid
      }
    }
    query HomePageQuery {
      wpPage(isFrontPage: { eq: true }) {
        id
        content
        seo {
          ...SEO
        }
        pageFields {
          showheroimage
          heroimage {
            text
            overlay
            opacity
            image {
              localFile {
                publicURL
              }
              altText
              description
            }
          }
        }
        homeFields {
          sectionone {
            text
            image {
              localFile {
                childImageSharp {
                  ...SectionImage
                }
              }
            }
          }
          sectiontwo {
            text
            image {
              localFile {
                childImageSharp {
                  ...SectionImage
                }
              }
            }
          }
          reverencelink {
            ... on WpPage {
              id
              uri
            }
          }
        }
        servicesFields {
          one {
            title
            text
            bg {
              localFile {
                childImageSharp {
                  fixed(width: 260, height: 285, quality: 70) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          three {
            text
            title
            bg {
              localFile {
                childImageSharp {
                  fixed(width: 260, height: 285, quality: 70) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          two {
            text
            title
            bg {
              localFile {
                childImageSharp {
                  fixed(width: 260, height: 285, quality: 70) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
        addContact {
          addcontact
          addcontactform
        }
      }
    }
  `)
  const { showheroimage, heroimage } = { ...wpPage?.pageFields }
  const { sectionone, sectiontwo, reverencelink } = { ...wpPage?.homeFields }
  const { one, two, three } = { ...wpPage?.servicesFields }
  const { content, addContact, seo } = { ...wpPage }
  const showContactButton = Boolean(addContact?.addcontact)
  const showContactForm = Boolean(addContact?.addcontactform)

  return (
    <>
      <SEO seo={seo} />
      {showheroimage ? (
        <HeroImage
          bgImg={heroimage?.image?.localFile?.publicURL}
          light={heroimage?.overlay === "licht"}
          text={heroimage?.text}
          opacity={heroimage?.opacity}
        />
      ) : null}
      <Layout>
        <Container maxWidth="md" disableGutters>
          <Spacer minHeight={90} />
          <TextWrapper>
            <Typography
              component={WpContent}
              dangerouslySetInnerHTML={{ __html: String(content) }}
            />
          </TextWrapper>
          <Spacer minHeight={90} />
        </Container>

        <Section section={sectionone} direction="row" />
        <Spacer />
        <Section section={sectiontwo} direction="row-reverse" />
        <Spacer minHeight={72} />

        <Container maxWidth="md" disableGutters>
          <Cards cards={[one, two, three]} />
        </Container>

        <Spacer />
        {showContactButton ? (
          <>
            <Center>
              <ContactButton />
            </Center>
            <Spacer minHeight={38} />
          </>
        ) : null}

        <Container maxWidth="md" disableGutters>
          <Reviews />
          {reverencelink?.uri ? (
            <PaddingCenter>
              <Button
                component={Link}
                to={String(reverencelink?.uri)}
                variant="contained"
                size="large"
                color="primary"
              >
                bekijk alle referenties
              </Button>
            </PaddingCenter>
          ) : null}
        </Container>

        {showContactForm ? (
          <>
            <Spacer />
            <Center>
              <ContactForm />
            </Center>
          </>
        ) : null}
        <Spacer />
      </Layout>
    </>
  )
}

export default IndexPage
