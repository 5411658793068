import { AppBar, CardContent, Typography } from "@material-ui/core"
import Card from "@material-ui/core/Card"
import Img, { FixedObject } from "gatsby-image"
import React from "react"
import styled from "styled-components"
import { flexCenter } from "../../theme/globalStyles"

type Maybe = null | undefined

interface childImageSharp {
  fixed: FixedObject | {} | Maybe
}
interface localFile {
  childImageSharp: childImageSharp | Maybe
}
interface Image {
  localFile: localFile | Maybe
}

interface ICard {
  text: string | Maybe
  title: string | Maybe
  bg: Image | Maybe
}

interface IProps {
  cards: Array<ICard | null | undefined>
}

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

const CardWrapper = styled(Card)`
  width: 276px;
  margin: ${({ theme }) => theme.spacing(2)}px;
`

const Bar = styled(AppBar)`
  padding: ${({ theme }) => `${theme.spacing(1.5)}px ${theme.spacing(1)}px`};
  height: ${({ theme }) => theme.spacing(10)}px;
  ${flexCenter};
`

const Content = styled(CardContent)`
  height: 285px;
  padding: 0;
  position: relative;
`

const ContentWrapper = styled.div`
  height: 316px;
  ${flexCenter}
`

const Text = styled(props => <Typography {...props} component="div" />)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${flexCenter};
  flex-direction: column;
  z-index: 5;
  background-color: rgba(255, 255, 255, 0.9);
  padding: ${({ theme }) => theme.spacing(1.5)}px;
`

const Cards: React.FC<IProps> = ({ cards }) => (
  <CardsWrapper>
    {cards.map((card, i) => {
      const { fixed } = { ...card?.bg?.localFile?.childImageSharp }

      return (
        <CardWrapper key={i}>
          <Bar position="static">
            <Typography component="span" variant="h6" align="center">
              {card?.title}
            </Typography>
          </Bar>
          <ContentWrapper>
            <Content>
              <Text dangerouslySetInnerHTML={{ __html: String(card?.text) }} />
              {fixed && <Img fixed={fixed as FixedObject} />}
            </Content>
          </ContentWrapper>
        </CardWrapper>
      )
    })}
  </CardsWrapper>
)

export default Cards
